import EventBus from "../vue/EventBus.js";
import $ from 'jquery';
window.$ = $;

if (document.getElementById("smartphone-scanner") !== null) {
    var product_found = false;
    var last_scanned = '';
    var last_result = {};

    window.addEventListener('load', function () {
        let selectedDeviceId;
        const codeReader = new ZXing.BrowserMultiFormatReader()
        codeReader.getVideoInputDevices()
        .then((videoInputDevices) => {
            const sourceSelect = document.getElementById('sourceSelect')
            selectedDeviceId = videoInputDevices[0].deviceId
            if (videoInputDevices.length >= 1) {
                videoInputDevices.forEach((element) => {
                    const sourceOption = document.createElement('option')
                    sourceOption.text = element.label
                    sourceOption.value = element.deviceId
                    sourceSelect.appendChild(sourceOption)
                })

                sourceSelect.onchange = () => {
                    selectedDeviceId = sourceSelect.value;
                };

                const sourceSelectPanel = document.getElementById('sourceSelectPanel')
                if (videoInputDevices.length > 1) {
                    sourceSelectPanel.style.display = 'block'
                } else {
                    $('startButton').click();
                }
            }

            document.getElementById('startButton').addEventListener('click', () => {
                codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
                    if(!product_found) {
                        if (result) {
                            if(result.text != last_scanned) {
                              console.log(result.text)
                                $.get(TRANSLATIONS.scan_product_url + "/" + result.text, function(data) {
                                    last_result = data;
                                    $("#result h3").html(data.name);
                                    if(Array.isArray(data.images)) {
                                        $("#result img").attr('src', data.images[0]);
                                    }
                                    $("#result").show();

                                });
                                product_found = true;
                                last_scanned = result.text;
                            }
                        }
                    }

                    if (err && !(err instanceof ZXing.NotFoundException)) {
                        console.error(err)
                    }
                })
                console.log(`Started continous decode from camera with id ${selectedDeviceId}`)
            })

            document.getElementById('resetButton').addEventListener('click', () => {
                codeReader.reset()
            })
        })
        .catch((err) => {
            console.error(err)
        });

        $('.video_wrapper .btn-primary').on('click', function () {
            console.log(last_result);
            $.post(TRANSLATIONS.add_product_url + last_result.id, {'quantity' : $('#result input').val()}, function(data) {
                last_scanned = '';
                product_found = false;
                $('#result input').val(1);
                $('#result').hide();
                EventBus.emit('update-headercart');
            });
        })
        $('.video_wrapper .btn-secondary').on('click', function () {
            last_scanned = '';
            product_found = false;
            $('#result').hide();
        })
    })
}
