$(".carousel__homepage").owlCarousel({
  loop: true,
  margin: 10,
  dots: true,
  items: 1,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 5000,
  autoplaySpeed: 1000,
});

$(".carousel__usp").owlCarousel({
  margin: 0,
  nav: false,
  dots: false,
  autoplay: true,
  navSpeed: 1000,
  touchDrag: false,
  mouseDrag: false,
  autoplayHoverPause: true,
  animateOut: "fadeOut",
  animateIn: "fadeIn",
  autoplayTimeout: 5000,
  autoplayHoverPause: false,
  responsive: {
    0: {
      loop: true,
      items: 1
    },
    992: {
      loop: false,
      items: 6,
      autoWidth: true
    }
  }
});

$(".product-slider").owlCarousel({
  loop: true,
  items: 1,
  autoplay: false,
  nav: true,
  thumbs: true,
  thumbsPrerendered: true,
  thumbContainerClass: "owl-thumbs",
  thumbItemClass: "owl-thumb-item"
});

function owlInitialize() {
  if ($(window).width() < 992) {
    $(".carousel__products").owlCarousel({
      margin: 10,
      dots: false,
      items: 4,
      stagePadding: 60,
      responsive: {
        0: {
          items: 1,
          loop: true,
        },
        568: {
          items: 2,
          loop: true
        }
      }
    });
  } else {
    $(".owl-carousel.carousel__products").owlCarousel("destroy");
  }
}
$(document).ready(function () {
  owlInitialize();
});
$(window).resize(function () {
  owlInitialize();
});


var owl = $('.product-img .owl-carousel');
owl.owlCarousel();
owl.on('changed.owl.carousel', function (event) {
  $('.owl-thumbs').animate({
    scrollTop: $(".owl-thumb-item.active").position().top
  }, 500);
})
