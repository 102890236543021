import 'bootstrap';

$(".faq-category-title, .faq-category-question").on('click', function () {
  const target = $('[id="' + $.attr(this, "href").substr(1) + '"]');
  const targetAnswer = $('[id="' + $.attr(this, "href").substr(1) + '-answer"]');

  if(target && targetAnswer) {
    $("html, body").animate(
      {
        scrollTop: target.offset().top - $("header").height(),
      },
      500
    );

    targetAnswer.addClass("show");
  }

  return false;
});
