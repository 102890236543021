import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";
import EventBus from "../vue/EventBus.js";

if (document.getElementById("selected") !== null) {
  const cart = createApp({
    data() {
      return {
        selected_items: [],
        list: {},
        action: 0,
        search_query: "",
        select_all: 0,
        url_prefix: "",
        sortorder: "created_at:desc",
        active_product_notification_product: {},
        translations: TRANSLATIONS,
        list_type: "selected",
        list_type_copy: "favorite",
        add_product_value: "",
        add_product_product: "",
        add_product_product: "",
        show_add_product: false,
      };
    },
    methods: {
      changed(item, refresh_all = true) {
        const vm = this;

        this.clearTimeout(item);

        item.debounce = setTimeout(() => {
          var debounce = item.debounce;
          var post_data = {
            quantity: item.quantity,
            note: item.note,
          };
          vm.$http
            .post(
              "/ajax/" + vm.list_type + "/add/" + item.product.id,
              post_data
            )
            .then(() => {
              if (refresh_all && debounce == item.debounce) {
                vm.getData();
              }
            });
        }, 400);
      },
      clearTimeout(item) {
        if (item.debounce) {
          clearTimeout(item.debounce);
        }
      },
      remove(item) {
        const vm = this;
        this.$http
          .delete("/ajax/" + this.list_type + "/remove/" + item.product.id)
          .then(() => {
            vm.getData();
          });
      },
      toCart(item) {
        console.log(item.product.id);
        var post_data = {
          quantity: item.quantity,
        };

        this.$http
          .post("/ajax/cart/add/" + item.product.id, post_data)
          .then(() => EventBus.emit("update-headercart"));
      },
      selectAll() {
        var item_keys = Object.keys(this.list.items);
        for (var key of item_keys) {
          this.list.items[key].selected = this.select_all;
        }
      },
      clearList() {
        const vm = this;
        this.$http.delete("/ajax/" + this.list_type + "/clear").then(() => {
          vm.getData();
        });
      },
      copyToList() {
        this.getSelectedItems();
        this.action = 0;

        var post_data = {
          items: this.selected_items,
        };
        if (this.selected_items.length > 0) {
          this.$http
            .post(
              "/ajax/" + this.list_type + "/" + this.list_type_copy + "/copy",
              post_data
            )
            .then((response) => (window.location.href = response.data.route));
        }
      },
      itemFilter() {
        const vm = this;
        if (this.search_query != "") {
          return $.map(this.list.items, (item) => {
            if (
              item.product.translation.name
                .toLowerCase()
                .search(vm.search_query.toLowerCase()) >= 0 ||
              item.product.itemcode
                .toLowerCase()
                .search(vm.search_query.toLowerCase()) >= 0
            ) {
              return item;
            }
          });
        }
        return this.list.items;
      },
      getData() {
        const vm = this;
        this.getSelectedItems();

        this.$http
          .get(
            "/ajax/" + this.list_type + "/content?sortorder=" + this.sortorder,
            {
              headers: {
                'Accept-Language': LANGUAGE,
              }
            }
          )
          .then(function (response) {
            vm.list = response.data;

            var item_keys = Object.keys(vm.list.items);
            for (var key of item_keys) {
              if (vm.selected_items.includes(vm.list.items[key].product.id)) {
                vm.list.items[key].selected = 1;
              }
            }
            vm.$nextTick(() => {
              vm.scrollToAnchor();
            });
          });
      },
      getSelectedItems() {
        if (typeof this.list.items != "undefined") {
          this.selected_items = [];

          var item_keys = Object.keys(this.list.items);
          for (var key of item_keys) {
            if (this.list.items[key].selected) {
              this.selected_items.push(this.list.items[key].product.id);
            }
          }
        }
      },
      parseEntities(string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = string;
        return txt.value;
      },
      scrollToAnchor() {
        if (window.location.hash.substr(1) !== "") {
          const anchor = window.location.hash.substr(1);
          if ($("#" + anchor).length) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#" + anchor).offset().top -
                  ($("header").height() +
                    $(".sticky-header").first().height() +
                    20),
              },
              0
            );
          }
        }
      },
      //custom from favorites
      setCopyList(list) {
        this.list_type_copy = list;

        this.copyToList();
      },
      addProductsButton() {
        this.show_add_product = !this.show_add_product;
      },
      keymonitor(event) {
        if (event.key == 'Enter') {
          this.addProduct();
        } else if (this.add_product_value.length == 13) {
          this.checkProduct();
        } else {
          this.add_product_product = "Invalid EAN-13";
        }
      },
      addProduct() {
        const vm = this;
        if (this.add_product_value.length == 13) {
          this.$http
            .post(
              "/ajax/" + this.list_type + "/add/ean/" + this.add_product_value
            )
            .then((response) => {
              var add_product_product = "Product not found";
              if (Boolean(response.data)) {
                var add_product_product = vm.add_product_product + " added";
              }

              vm.$nextTick(() => {
                vm.add_product_value = "";
                vm.add_product_product = add_product_product;
              });
              vm.getData();
            })
            .catch(function (error) {
              vm.add_product_value = "";
            });
        }
      },
      checkProduct() {
        this.add_product_product = "";
        if (this.add_product_value.length == 13) {
          const vm = this;
          this.$http
            .get(
              "/ajax/" +
                this.list_type +
                "/product_info/ean/" +
                this.add_product_value
            )
            .then(function (response) {
              if (Boolean(response.data)) {
                vm.add_product_product = response.data.translation.name;
              } else {
                vm.add_product_product = "Product not found";
              }
            })
            .catch(function (error) {
              vm.add_product_product = "Error while checking product";
            });
        }
      },
    },
    created() {
      this.url_prefix = URL_PREFIX;
      this.getData();
    },
    mounted() {},
  })
    .use(VueAxios, axios)
    .mount("#selected");
}
