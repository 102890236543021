var toggleProductInfo = function() {
  if($("#tab_databank .product_info_toggle").is(':checked')) {
    $('.product_info').removeClass('d-none');
  } else {
    $('.product_info').addClass('d-none');
  }

  if($("#tab_databank .product_image_toggle").is(':checked')) {
    $('.product_image').removeClass('d-none');
  } else {
    $('.product_image').addClass('d-none');
  }
}

$( document ).ready(function() {
  $("#tab_databank .product_info_toggle, #tab_databank .product_image_toggle").on('change', function (e) {
    e.preventDefault();

    toggleProductInfo();
  });

  // $("#tab_databank").on('change', function (e) {
  //   $('#tab_databank .btn-primary').removeClass('disabled');
  //   if(!$("#tab_databank .terms_agreements").is(':checked')) {
  //     $('#tab_databank .btn-primary').addClass('disabled');
  //     console.log('terms_agreements');
  //   } else if(
  //     !$("#tab_databank [name='product_images']").is(':checked') &&
  //     !$("#tab_databank [name='product_manuals']").is(':checked') &&
  //     !$("#tab_databank [name='product_info']").is(':checked')
  //   ) {
  //     console.log('checkboxes');
  //     $('#tab_databank .btn-primary').addClass('disabled');
  //   } else if($("#tab_databank [name='email']").val() == '') {
  //     console.log('email');
  //     $('#tab_databank .btn-primary').addClass('disabled');
  //   }
  // });

  $("#tab_databank").on('submit', function (e) {
    console.log('submit');
    $('#databank-errors').addClass('d-none').html('');

    if(
      !$("#tab_databank [name='product_images']").is(':checked') &&
      !$("#tab_databank [name='product_manuals']").is(':checked') &&
      !$("#tab_databank [name='product_info']").is(':checked')
    ) {
      e.preventDefault();
      $('#databank-errors').removeClass('d-none').html($('#databank-errors').html() + databank_errors.no_data_selected);
      // $("#tab_databank [name='product_images'], #tab_databank [name='product_manuals'], #tab_databank [name='product_info']").addClass('error');
    }

    if($("#tab_databank [name='email']").val() == '') {
      e.preventDefault();
      $('#databank-errors').removeClass('d-none').html($('#databank-errors').html() + databank_errors.email);
      // $("#tab_databank [name='email']").addClass('error');
    }

    if(!$("#tab_databank .terms_agreements").is(':checked')) {
      e.preventDefault();
      $('#databank-errors').removeClass('d-none').html($('#databank-errors').html() + databank_errors.terms_agreements);
      // $("#tab_databank .terms_agreements").addClass('error');
    }
  });

  setTimeout(toggleProductInfo(), 500);
});

