import $ from 'jquery';
window.$ = $;

$(function() {
  //login-form
  $('.login-form').on('submit', function (e) {
    e.preventDefault();

    $('.progress-container').css('opacity', 1);
    $('.login-button').attr('disabled', true).addClass('loading');
    $('.forgot-password').addClass('loading');

    $.ajax({
      url: "/auth/login",
      method: 'POST',
      data: $(this).serialize(),
      dataType: 'json',
      success: function (data) {
        $('.login-errors').html("");
        $('.login-errors').addClass('d-none');

        if (data.success) {
          dataLayer.push({'event': 'login'});
          if (data.redirect) {
            window.location = data.redirect;
          } else {
            location.reload();
          }
        } else {
          var errors = Array();
          $.each(data.errors, function () {
            $.each(this, function () {
              errors.push(this);
            });
          });

          if (errors.length) {
            $('.login-errors').html(errors.join('<br />'));
            $('.login-errors').removeClass('d-none');
            $('.forgot-password-success').addClass('d-none');
            $('.forgot-password-fail').addClass('d-none');

            $('.progress-container').css('opacity', 0);
            $('.login-button').attr('disabled', false).removeClass('loading');
            $('.forgot-password').removeClass('loading');
          }
        }
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log(errorThrown);
        dataLayer.push({'event': 'login_failure'});
        location.reload();
      }
    });
  });

  //forgot password
  $('.forgot-password').on('click', function (e) {
    e.preventDefault();

    $.ajax({
      url: "/auth/forgot-password",
      method: 'POST',
      data: $('.login-form').serialize(),
      dataType: 'json',
      success: function (data) {
        $('.forgot-password-success').addClass('d-none');
        $('.forgot-password-fail').addClass('d-none');
        $('.login-errors').addClass('d-none');

        if(data) {
          $('.forgot-password-success').removeClass('d-none');
          dataLayer.push({'event': 'forgot_password'});
        } else {
          $('.forgot-password-fail').removeClass('d-none');
        }
      }
    });
  });

  //AJAX search results -- Header
  var search_counter = 0;
  var search_more_results_then_shown = false;
  var debounce_search = 0;

  function fetch_search_results(query = '', counter = 0) {
    // $('#searchitems').removeClass('show');
    $.ajax({
      url: URL_PREFIX +"/ajax/search/json",
      method: 'GET',
      data: {
        query: encodeURIComponent(query)
      },
      dataType: 'json',
      success: function (data) {
        search_more_results_then_shown = false;
        if (counter == search_counter) {
          var results = Object.keys(data.products).length;
          if(results > 0) {
            if(results > 6) {
              data.products = data.products.slice(0, 5);
              search_more_results_then_shown = true;
            }

            addSearchResults(data);
            $('#searchitems').addClass('show');
          } else {
            $('#searchitems').removeClass('show');
          }
        }
      }
    });
  }

  function addSearchResults(data) {
    const markup = `
      <ul class="search_items">
        ${data.categories.map(item => `
        <li>
          <a href="${item.url}">
            <div class="search-image" style="background-image: url('${item.img}')"></div>
            <span class="search-title">${item.title}</span>
          </a>
        </li>`).join('')}
        ${data.products.map(item => `
        <li>
          <a href="${item.url}">
            <div class="search-image" style="background-image: url('${item.img}')"></div>
            <span class="search-title">${item.sku} - ${item.title}</span>
          </a>
        </li>`).join('')}
      </ul>`;

    if (data.products.length == 0) {
      //TODO show message that there are no results?
      $('#searchitems').removeClass('show');
    } else {
      if(search_more_results_then_shown) {
        //show that there are more results then shown, press enter do go to searchpage with all results
      }
      $('.searchitems').html(markup);
    }
  }

  $('.client-application').on('focus', 'input', function (e) {
    var field_subgroup = $('label[for="'+ $(this).attr('id') +'"]').data('subgroup');
    var field_name = $('label[for="'+ $(this).attr('id') +'"]').text().replace('*', '');
    if(field_subgroup !== undefined) {
      field_name = field_subgroup + ': ' + field_name;
    }

    dataLayer.push({
      'event': 'form_interaction',
      'params': {
        'formField': field_name
      }

    });
  });

  $(document).on('click', '.search__settings', function (e) {
    $('#searchitems').removeClass('show');
    if($('#searchsettings').hasClass('show')) {
      $('#searchsettings').removeClass('show');
    } else {
      $('#searchsettings').addClass('show');
    }
  });

  $(document).on('focus', '#main-searchbar', function (e) {
    search_counter++;
    var query = $('#main-searchbar').val();
    fetch_search_results(query, search_counter);
  });

  $(document).on('change', '#searchsettings input', function (e) {
    var name = $(this).attr('name');
    var value = $(this).is(':checked');

    $.ajax({
      url: "/ajax/search/settings",
      method: 'POST',
      data: {
        name: name,
        value: value,
      },
      dataType: 'json'
    });
  });

  $(document).on('click', '#searchsettings .reset', function (e) {
    e.preventDefault();
    $.ajax({
      url: "/ajax/search/settings/reset",
      method: 'GET',
      dataType: 'json'
    });

    $('#searchsettings input[name="search.search_on_itemcode"]').prop('checked', true);
    $('#searchsettings input[name="search.search_on_barcode"]').prop('checked', true);
    $('#searchsettings input[name="search.search_on_product_name"]').prop('checked', true);
    $('#searchsettings input[name="search.search_on_product_description"]').prop('checked', false);
    $('#searchsettings input[name="search.search_on_product_creation_date"]').prop('checked', false);

    $('#searchsettings input[name="search.show_only_visible_not_orderable_products"]').prop('checked', false);
    $('#searchsettings input[name="search.show_only_products_in_stock"]').prop('checked', false);
  });

  $(document).on('click', '.search__button.main-search', function (e) {
    var query = $('#main-searchbar').val();

    window.location = URL_PREFIX + "/search?query=" + encodeURIComponent(query);
  });

  $(document).on('keyup', '#main-searchbar', function (e) {
    var query = $(this).val();

    if (e.keyCode == 13) {
      window.location = URL_PREFIX + "/search?query=" + encodeURIComponent(query);
    } else {
      search_counter++;
      clearTimeout(debounce_search);
      debounce_search = setTimeout(function () {
        fetch_search_results(query, search_counter);
      }, 300);
    }
  });

  $('body').not('#searchitems').click(function () {
    $('#searchitems').removeClass('show');
  });

  $('body').on('submit', '.product-notification-form', function (e) {
    e.preventDefault();
    $('.product-notification-form input[type="email"]').val($(this).find('input[type="email"]').val());

    var info = $('.modal.show .product-notification-info');

    $.ajax({
      url: URL_PREFIX + "/product/notification-register",
      type: 'POST',
      data: $(this).serialize()
    }).then(function (response) {
      if (response.success) {
        info.removeClass('text-danger');
        info.addClass('text-success');
        info.text(response.success_string);
        setTimeout(function() {
          $('.modal.show').modal('hide')
        }, 3000);
      } else {
        info.removeClass('text-success');
        info.addClass('text-danger');
        info.text(response.error);
      }

      setTimeout(function() {
        info.text('');
      }, 3000);
    });
  });
});
