$('[data-fancybox]').fancybox({
  infobar: false,
  buttons: [
    "close"
  ],
  loop: true,
  iframe : {
    preload : true,
    css : {
      width : '800px',
      height : '800px',
    }
  }
});
