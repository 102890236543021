import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";
import { ModelSelect } from 'vue-search-select'

if (document.getElementById("cart-confirm") !== null) {
  const cart = createApp({
    components: {
      ModelSelect,
    },
    data() {
      return {
        dropshipping_options: dropshipping_options,
        country_options: {},
        new_address_error: "",
        submit_active: false,
        agree_term: 0,
        reference: "",
        errors: {
          agree_term: 0,
          reference: 0,
        },
        fields: {
          attn: "",
          street: "",
          street_number: "",
          street_number_suffix: "",
          zipcode: "",
          city: "",
          country: "NL",
        },
        dropshipping_selected: {
          value: 0,
          text: "",
        },
      };
    },
    methods: {
      addAddress() {
        const vm = this;
        if (vm.submit_active == false) {
          vm.submit_active = true;
          this.$http
            .post("/ajax/cart/address", this.fields)
            .then(function (response) {
              if (response.data.succes) {
                $("#add_address").modal("hide");
                vm.getAddresses();
              } else {
                vm.new_address_error = response.data.error;
              }
              vm.submit_active = false;
            });
        }
      },
      checkForm() {
        this.checkAgreeTerm();
        this.checkReference();
      },
      checkReference() {
        if (this.reference.length == 0) {
          this.errors.reference = 1;
        } else {
          this.errors.reference = 0;
        }
        this.reference = this.reference.substring(0, 26);
      },
      checkAgreeTerm() {
        if (this.agree_term != 1) {
          this.errors.agree_term = 1;
        } else {
          this.errors.agree_term = 0;
        }
      },
      getData() {
        this.getAddresses();
        this.getCountries();
      },
      getAddresses() {
        const vm = this;
        this.$http.get("/ajax/cart/addresses").then(function (response) {
          vm.dropshipping_options = response.data;
        });
      },
      getCountries() {
        const vm = this;
        this.$http.get("/ajax/cart/countries").then(function (response) {
          vm.country_options = response.data;
        });
      },
    },
    created() {
      this.getData();
    },
    mounted() {},
  })
    .use(VueAxios, axios)
    .mount("#cart-confirm");
}
