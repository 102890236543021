import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";

if (document.getElementById("tab_question") !== null) {
  const product_quenstion = createApp({
    data() {
      return {
        message: "",
        send: false,
        email: email,
      };
    },
    methods: {
      sendMessage() {
        const vm = this;

        this.$http
          .post("/ajax/product/question/" + PRODUCT_ID, {
            message: this.message,
            email: this.email,
          })
          .then(function (response) {
            vm.message = "";
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    },
  })
    .use(VueAxios, axios)
    .mount("#tab_question");
}
