import $ from 'jquery';
window.$ = $;

import "select2";

$('#newsletter-form').on('submit', function (e) {
  e.preventDefault();

  const email = $('#newsletter-register-input').val();
  const info = $('#newsletter-form #newsletter-info');

  $.ajax({
    url: "/newsletter-register",
    type: 'POST',
    data: {
      'email': email
    }
  }).then(function (response) {
    if (response.success) {
      info.removeClass('text-danger');
      info.addClass('text-success');
      info.text(response.success_string);
      dataLayer.push({
        'event': 'newsletter_subscription',
        'params': {
          'customer': LOGGED_IN ? 'ja' : 'nee'
        }
      });
    } else {
      info.removeClass('text-success');
      info.addClass('text-danger');
      info.text(response.error);
    }
  });
});

// In your Javascript (external .js resource or <script> tag)
$(function() {
  // $('.address-select2').select2({
  //     theme: 'bootstrap',
  // });

  $(document).on('select2:open', (e) => {
      const selectId = e.target.id;
      $(".select2-search__field[aria-controls='select2-"+selectId+"-results']").each(function (key,value,){
          value.focus();
      });
  });
});

var checkBecomeClientFormOnChange = false;
function checkBecomeclientForm () {
  if(checkBecomeClientFormOnChange) {
    var errors = 0;
    var requiredFields = Array(
      'salutation',
      'first_name',
      'last_name',
      'email_address',
      'phone_number',
      'company_name',
      'chamber_of_commerce_number',
      'vat_number',
      'default_address_country',
      'default_address_zipcode',
      'default_address_number',
      'default_address_street',
      'default_address_city',
    );

    if($('#become-client [name="different_delivery_address"').attr('aria-expanded') == 'true') {
      requiredFields.push('delivery_address_address_country');
      requiredFields.push('delivery_address_address_zipcode');
      requiredFields.push('delivery_address_address_number');
      requiredFields.push('delivery_address_address_street');
      requiredFields.push('delivery_address_address_city');
    }

    requiredFields.forEach(field => {
      if(!$('#become-client [name="' + field + '"').val() || $('#become-client [name="' + field + '"').val() == 0) {
        $('#become-client [name="' + field + '"').addClass('error');
        errors++;
      } else {
        $('#become-client [name="' + field + '"').removeClass('error');
      }
    });

    if($('#become-client [name="default_address_country"').val() == 0) {
      $('[aria-controls="select2-default_become_client_address_country-container"]').css({
        'border-color': '#ff3939',
      });
    } else {
      $('[aria-controls="select2-default_become_client_address_country-container"]').css({
        'border-color': '#eef1f6',
      });
    }

    if($('#become-client [name="delivery_address_address_country"').val() == 0) {
      $('[aria-controls="select2-delivery_address_become_client_address_country-container"]').css({
        'border-color': '#ff3939',
      });
    } else {
      $('[aria-controls="select2-delivery_address_become_client_address_country-container"]').css({
        'border-color': '#eef1f6',
      });
    }

    if($('#become-client [name="email_address"').val()) {
      var email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if ($('#become-client [name="email_address"').val().match(email_regex)) {
        $('#become-client [name="email_address"').removeClass('error');
        $('#email-error').addClass('d-none');
      } else {
        $('#become-client [name="email_address"').addClass('error');
        $('#email-error').removeClass('d-none');
        errors++;
      }
    }

    if($('#become-client [name="vat_number"').val()) {
      var vat_number_regex = {
        'NLD': /^NL[0-9]{9}B[0-9]{2}$/,
        'BEL': /^BE[0-9]{9,}$/,
        'DEU': /^DE[0-9]{9,}$/,
        'AUT': /^[A-Z]{2,3}[0-9]{1,}$/,
        'OTHER': /^[A-Z]{2}[0-9]{1,}$/,
      }

      $('#become-client .vat-number-error').addClass('d-none');

      if($('#become-client [name="different_delivery_address"').attr('aria-expanded') == 'true') {
        var country = $('#become-client [name="delivery_address_address_country"').val();
      } else {
        var country = $('#become-client [name="default_address_country"').val();
      }

      if (country == 'NLD' || country == 'BEL' || country == 'DEU' || country == 'AUT') {
        if($('#become-client [name="vat_number"').val().match(vat_number_regex[country])) {
          $('#become-client [name="vat_number"').removeClass('error');
        } else {
          $('#become-client [name="vat_number"').addClass('error');
          $('#vat-number-error-'+ country).removeClass('d-none');
          errors++;
        }
      } else {
        if ($('#become-client [name="vat_number"').val().match(vat_number_regex['OTHER'])) {
          $('#become-client [name="vat_number"').removeClass('error');
        } else {
          $('#become-client [name="vat_number"').addClass('error');
          $('#vat-number-error-OTHER').removeClass('d-none');
          errors++;
        }
      }
    }

    if($('#become_client_accepted_conditions:checked').length) {
      $('#accepted-conditions-error').addClass('d-none');
    } else {
      $('#accepted-conditions-error').removeClass('d-none');
      errors++;
    }

    return errors;
  }

  return 1;
}

$('#become-client').on('submit', function (e) {
  checkBecomeClientFormOnChange = true;

  if(checkBecomeclientForm() > 0) {
    e.preventDefault();
    return false
  }
});

$('#become-client input, #become-client select').on('change', function (e) {
  e.preventDefault();

  var target = $(this).parents('div.autocomplete-address').data('for');
  if($('#' + target + '_become_client_address_country').val() != 'NLD') {
    $('#' + target + '_become_client_address_street')
      .attr('readonly', false)
      .removeClass('disabled');
    $('#' + target + '_become_client_address_city')
      .attr('readonly', false)
      .removeClass('disabled');
    $('#' + target + '_become_client_manual_override_address')
      .addClass('d-none');
  } else {
    $('#' + target + '_become_client_address_street')
      .attr('readonly', true)
      .addClass('disabled');
    $('#' + target + '_become_client_address_city')
      .attr('readonly', true)
      .addClass('disabled');
    $('#' + target + '_become_client_manual_override_address')
      .removeClass('d-none');
  }

  checkBecomeclientForm();
});


$('#become-client .autocomplete-address input').on('keyup', function (e) {
  e.preventDefault();

  var target = $(this).parents('div.autocomplete-address').data('for');
  autocompleteAddress(this, target);
});

$('#become-client .autocomplete-address select').on('change', function (e) {
  e.preventDefault();

  var target = $(this).parents('div.autocomplete-address').data('for');
  autocompleteAddress(this, target);
});

function autocompleteAddress (object, target) {
  if($(object).attr('id') == target + '_become_client_address_number_addition') {
    return;
  }

  if(
    $('#' + target + '_become_client_address_country').val() != 0 &&
    $('#' + target + '_become_client_address_zipcode').val() != '' &&
    $('#' + target + '_become_client_address_number').val() != ''
  ) {
    var country = encodeURIComponent($('#' + target + '_become_client_address_country').val());
    var zipcode = encodeURIComponent($('#' + target + '_become_client_address_zipcode').val().replace(' ', ''));
    var number = parseInt($('#' + target + '_become_client_address_number').val());

    if(country == 'NLD') {
      $.ajax({
        url: "/become-client/get-address/" + country + "/" + zipcode + "/" + number,
        type: 'GET',
      }).then(function (response) {
        $('#' + target + '_become_client_address_street')
          .attr('readonly', true)
          .addClass('disabled')
          .val(response.street);
        $('#' + target + '_become_client_address_city')
          .attr('readonly', true)
          .addClass('disabled')
          .val(response.city);
      }).fail(function (response) {
        $('#' + target + '_become_client_address_street')
          .attr('readonly', false)
          .removeClass('disabled')
          .val("");
        $('#' + target + '_become_client_address_city')
          .attr('readonly', false)
          .removeClass('disabled')
          .val("");
      });
    }
  }
}

$('#become-client .manual_override_address').on('click', function (e) {
  e.preventDefault();
  var target = $(this).data('for');

  $('#' + target + '_become_client_address_street').attr('readonly', false);
  $('#' + target + '_become_client_address_city').attr('readonly', false);
});

$('#contact-form').on('submit', function (e) {
  e.preventDefault();
  removeErrors();

  $.ajax({
    url: "/contact-request",
    type: 'POST',
    data: $('#contact-form').serialize()
  }).then(function (response) {
    console.log(response);
    if (response.success) {
      location.href = newContactSuccessRoute;
    } else {
      showErrors(response.errors, '#contact-form', 'contact_');
      jumpToTop(Object.keys(response.errors)[0], 'contact_');
    }
  }).fail(function (data) {
    const errors = data.responseJSON.errors;
    showErrors(errors, '#contact-form', 'contact_');
    jumpToTop(Object.keys(errors)[0], 'contact_');
  });
});

function showErrors(errors, form, prefix) {
  for (const [key, message] of Object.entries(errors)) {
    newKey = key.replace('.', '_');
    if(newKey == 'accepted_conditions'){
      const error_html = "<div class='ml-1 text-danger field-error small'>" + message + "</div>"
      $(form + ' #' + prefix + newKey).before(error_html);
    } else {
      const error_html = "<div class='text-danger field-error small'>" + message + "</div>"
      $(form + ' #' + prefix + newKey).after(error_html);
    }
  }
}

function removeErrors() {
  $('.field-error').remove();
}

function jumpToTop(id, prefix) {
  document.getElementById(prefix + id).parentElement.scrollIntoView(true);
  let scrolledY = window.scrollY;

  if(scrolledY){
    window.scroll(0, scrolledY - $('header').height() - 100);
  }
}
