$(document).ready(function () {
  var heighest_block = 0;
  $('.extra-information__block .description').each(function () {
    if($(this).innerHeight() > heighest_block) {
      heighest_block = $(this).innerHeight();
    }
  });

  $('.extra-information__block .description').css('height', heighest_block);
});
