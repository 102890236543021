import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";

if (document.getElementById("api_token_table") !== null) {
  const api_token_table = createApp({
    data() {
      return {
        tokens: [],
        new_application_name : '',
        new_token: '',
        show_modal: false,
        copy_text: TRANSLATIONS.copy_text,
        copied_text: TRANSLATIONS.copied_text,
        copy_token_button: TRANSLATIONS.copy_text,
      };
    },
    methods: {
      getData () {
          const vm = this;
          this.$http
            .get('/ajax/api/list')
            .then(function(response) {
              vm.tokens = response.data;
          });
      },
      create (new_application_name){
          const vm = this;
          var post_data = {'application_name': new_application_name};
          this.$http.post('/ajax/api/token', post_data).then(function(response) {
             vm.new_token = response.data;
             $('#new_token_modal').modal('show');
          });
      },
      set_active (token_id, is_active){
          is_active = is_active ? 1 : 0;
          this.$http.post('/ajax/api/set-active/' + token_id + '/' + is_active);
      },
      removeToken (token, index) {
          const vm = this;
          this.$http.delete('/ajax/api/token/' + token.id).then(function(response) {
              if(response.data.success){
                  vm.tokens.splice(index, 1);
              }
          });
      },
      copyToClipboard () {
          /* Get the text field */
          var copyText = document.getElementById("new-token");

          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /*For mobile devices*/

          /* Copy the text inside the text field */
          document.execCommand("copy");
          this.copy_token_button = this.copied_text;
          $('#copy-token-button').removeClass('btn-primary');
          $('#copy-token-button').addClass('btn-secondary');
      },
      modalClose () {
          this.getData();
          $('#new_token_modal').modal('hide');
          this.new_application_name = '',
          this.new_token = '',
          this.copy_token_button = this.copy_text;
          $('#copy-token-button').removeClass('btn-secondary');
          $('#copy-token-button').addClass('btn-primary');
      }
    },
    created() {
      this.copy_token_button = this.copy_text;

      this.getData();
    },
    mounted() {},
  })
    .use(VueAxios, axios)
    .mount("#api_token_table");
}
